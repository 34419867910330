<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel panel-primary">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Pengguna</h3>
          </div>
          <form
            @submit.prevent="postData"
            id="formPeserta"
            enctype="multipart/form-data"
          >
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="full_name">Nama Lengkap</label>
                        <input
                          v-model="form.full_name"
                          type="text"
                          name="full_name"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.full_name,
                          }"
                        />
                        <div
                          class="text-danger"
                          v-html="formValidate.full_name"
                        ></div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="username">Username</label>
                        <input
                          v-model="form.username"
                          type="text"
                          name="username"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.username,
                          }"
                        />
                        <div
                          class="text-danger"
                          v-html="formValidate.username"
                        ></div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="phone_number">Nomor HP/WA</label>
                        <input
                          v-model="form.phone_number"
                          type="number"
                          name="phone_number"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.phone_number,
                          }"
                        />
                        <div
                          class="text-danger"
                          v-html="formValidate.phone_number"
                        ></div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="level">Level User</label>
                        <select
                          v-model="form.level"
                          name="level"
                          class="form-control m-b-5"
                          :class="{
                            'is-invalid': formValidate.level,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="admin">Admin</option>
                          <option value="loket">Loket DLH</option>
                          <option value="kelurahan">Loket Kelurahan</option>
                          <option value="penagih">Verifikator Kecamatan</option>
                          <option value="satgas">Satgas Kelurahan</option>
                          <option value="guest">Guest</option>
                        </select>
                        <div
                          class="text-danger"
                          v-html="formValidate.level"
                        ></div>
                      </div>
                    </div>

                    <div
                      v-if="
                        form.level == 'kelurahan' ||
                        form.level == 'penagih' ||
                        form.level == 'satgas'
                      "
                      class="col-md-2"
                    >
                      <div class="form-group">
                        <label for="district">Kecamatan : </label>
                        <select
                          v-model="form.district"
                          name="district"
                          id="district"
                          v-on:change="fetchVillages($event)"
                          class="form-control"
                          :class="{
                            'is-invalid': formValidate.district,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option
                            v-for="districts in districtList"
                            :value="districts.id_kec"
                            :key="districts.id_kec"
                          >
                            {{ districts.nama_kecamatan }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      v-if="form.level == 'kelurahan' || form.level == 'satgas'"
                      class="col-md-2"
                    >
                      <div class="form-group">
                        <label for="village">Kelurahan : </label>
                        <select
                          v-model="form.village"
                          name="village"
                          id="village"
                          class="form-control"
                          :class="{
                            'is-invalid': formValidate.village,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option
                            v-for="villages in villageList"
                            :value="villages.id_kel"
                            :key="villages.id_kel"
                          >
                            {{ villages.nama_kelurahan }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <a
                href="javascript:window.history.go(-1);"
                class="btn btn-default"
                >Batal</a
              >
              <button
                type="button"
                class="btn btn-teal pull-right"
                @click="postData()"
                :disabled="this.loading"
              >
                <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
                {{ $route.params.id ? "Update" : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      form: {
        id_users: "",
        full_name: "",
        phone_number: "",
        old_username: "",
        username: "",
        level: "",
        district: "",
        village: "",
      },
      formValidate: [],
      loading: false,
      districtList: [],
      villageList: [],
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.form.id_users = id;
      this.fetchUser();
      this.fetchDistricts();
    }
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/v1/users/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.go(-1);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchUser() {
      axios
        .get("/v1/users/detail/" + this.form.id_users)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          this.form.old_username = response.data.data.username;
          this.form.village = response.data.data.access_rights;
          this.form.district = this.form.village.substring(0, 6);
          axios
            .get("/v1/daerah/kelurahan/" + this.form.district, {
              headers: {
                token: this.userToken,
              },
            })
            .then((response) => {
              this.villageList = response.data.data;
            })
            .catch((error) => {
              console.log(error);
              this.error = error.message;
            });
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchDistricts() {
      axios
        .get("/v1/daerah/kecamatan/7271", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.districtList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchVillages(event) {
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.villageList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
